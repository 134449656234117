// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "d1a0c8884b91d81e101c2ae0df838618c70c2626",
  version: "1.103.0",
};
